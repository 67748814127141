<template>
  <div class="MyAssApplication">
    <!-- 360/KPI考核申请 -->
    <van-nav-bar :title="title"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('module.noMore')"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item, index) in assessList"
               :key="index"
               @click="gotoDetail(item)">
            <van-cell-group>
              <van-cell class="top_cell"
                        center
                        :title="is360 == 'true' ? item.itemname : item.itemname">
                <template #right-icon
                          v-if="!item.ischeck">
                  <van-icon :name="deleteAss"
                            style="margin-left: 10px"
                            @click.stop="deleteTag(item)" />
                </template>
              </van-cell>
              <van-cell class="btm_cell"
                        center>
                <template #title>
                  <span v-if="is360 == 'true'"
                        class="custom-title">{{
                    item.remark
                  }}</span>
                  <span v-else
                        class="custom-title">{{ item.remark }}</span>
                </template>
                <template #label>
                  <div class="pj_name">

                  </div>
                  <div class="pj_score">
                    <span v-if="is360 == 'true'"
                          class="custom-score">{{
                      item.itemvalue
                    }}</span>
                    <span v-else
                          class="custom-score">{{ item.itemvalue }}</span>
                  </div>
                </template>
              </van-cell>

            </van-cell-group>
            <svg-icon v-if="item.ischeck"
                      class="yshdtb"
                      icon-class="yishenhe"></svg-icon>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="footer">
      <div @click="addItem">{{$t('module.AddTo')}}</div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import {
  getPubCiList,
  deletePubCiRecord,
  getPerCiList,
  deletePerCiRecord
} from "@api/wxjx.js";
export default {
  data () {
    let title = this.$route.query.title;
    let is360 = this.$route.query.is360;
    let mautoid = this.$route.query.mautoid;
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      title,
      is360,
      mautoid,
      deleteAss: require("@/assets/img/deleteAss.png"),
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
    };
  },
  computed: {
    showFoot () {
      if (this.checkflag == 1 || this.checkflag == 4)
      {
        return true;
      } else
      {
        return false;
      }
    },
  },
  created () { },
  methods: {
    onClickLeft () {
      // this.$router.push('/jxMyAssTarget')
      this.$router.push({
        path: "/jxStatisticalData",
        query: { is360: this.is360 },
      });
    },
    // 查看某个“360指标方案”所有指标明细
    getMy360Indicator () {
      let _this = this;
      getPubCiList({
        empid: this.userInfo.empid,
        khtimeid: this.mautoid,
      }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    // 查看某个“KPI指标方案”所有指标明细接口
    getMyKpiIndicator () {
      let _this = this;
      getPerCiList({
        empid: this.userInfo.empid,
        khtimeid: this.mautoid,
      }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    onLoadAwait () {
      // 异步更新数据
      if (this.isLoadingAwait)
      {
        this.assessList = [];
        this.isLoadingAwait = false;
      }
      if (this.is360 == true || this.is360 == "true")
      {
        this.getMy360Indicator();
      } else
      {
        this.getMyKpiIndicator();
      }
    },
    onRefreshAwait () {
      this.assessList = [];
      this.finishedAwait = false;
      this.loadingAwait = true;
      this.onLoadAwait();
      Toast(this.$t('module.refreshSuccessfully'));
    },
    // 查看详情
    gotoDetail (item) {
      const detaId = item.autoid;
      const length = item.sqnum;
      this.$router.push({
        path: "/jxStatisticalDataDetails",
        query: {
          parentTitle: this.title,
          is360: this.is360,
          mautoid: this.mautoid,
          detaId: detaId,
          length: length,
          ischeck: item.ischeck
        },
      });
    },
    // 添加按钮
    addItem () {
      const length = this.assessList.length || 0;
      this.$router.push({
        path: "/jxStatisticalDataAdd",
        query: {
          parentTitle: this.title,
          is360: this.is360,
          mautoid: this.mautoid,
          detaId: "",
          length: length
        },
      });
      // this.$router.push('/jxMyAssTargetDetails')
    },
    // 删除按钮
    deleteTag (item) {
      console.log(item, "itemitem");
      if (this.is360 == true || this.is360 == "true")
      {
        // 删除360
        Dialog.confirm({
          title: this.$t("module.del"),
          message: this.$t("module.sureToDelete"),
        })
          .then(() => {
            deletePubCiRecord({ autoid: item.autoid }).then((res) => {
              if (res.iserror == 0)
              {
                Toast.success(this.$t('module.DelSuc'));
                this.onRefreshAwait();
              }
            });
          })
          .catch(() => { });
      } else
      {
        // 删除KPI
        Dialog.confirm({
          title: this.$t("module.del"),
          message: this.$t("module.sureToDelete"),
        })
          .then(() => {
            deletePerCiRecord({ autoid: item.autoid }).then((res) => {
              if (res.iserror == 0)
              {
                Toast.success(this.$t('module.DelSuc'));
                this.onRefreshAwait();
              }
            });
          })
          .catch(() => { });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.MyAssApplication {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .zb_group {
      // display: flex;
      border-left: 6px solid #2b8df0;
      margin: 30px 0;
      position: relative;
      .van-cell-group {
        .top_cell {
          .van-cell__title {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          .van-cell__value {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .btm_cell {
          .van-cell__title {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-title {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .van-cell__label {
            display: flex;
            justify-content: space-between;
            // text-align: right;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .pj_name {
              .text1,
              .text2 {
                padding: 0 4px;
                border: 1px solid #f53838;
                background: #f53838;
                border-radius: 50%;
                color: #fff;
              }
              .text2 {
                margin-left: 16px;
              }
              .time1 {
                padding-left: 10px;
                padding-right: 14px;
                border-right: 1px solid #999;
              }
              .time2 {
                padding: 0 10px;
              }
            }
            .custom-score {
              font-size: 48px;
              font-family: DIN;
              font-weight: bold;
              color: #2b8df0;
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    div {
      width: 50%;
      height: 70px;
      font-size: 36px;
      text-align: center;
      line-height: 70px;
      background: #2b8df0;
      color: #fff;
      border-radius: 12px;
    }
  }
  .yshdtb {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 25px;
    top: 35px;
    color: red;
  }
}
</style>